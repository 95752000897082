
import { defineComponent } from "vue";
import TabView from "primevue/tabview";
import TabPanel from "primevue/tabpanel";
import Button from "primevue/button";
import { mapActions, mapGetters, mapState } from "vuex";
import MessageBox from "./MessageBox.vue";
import { SalesInquiryMap } from "@/types/state/salesInquiry";
import Utils from "@/utility/utils";

export default defineComponent({
  components: {
    TabView,
    TabPanel,
    Button,
    MessageBox,
  },
  computed: {
    ...mapState(["salesInquiry"]),
    ...mapGetters({
      innerTabs: "salesInquiry/getInnerTabs",
      getLineItemsMap: "salesInquiry/getLineItemsMap",
      getActiveTab: "salesInquiry/getActiveTab",
      getSalesInquiryMap: "salesInquiry/getSalesInquiryMap",
      getActiveLineItemsTab: "salesInquiry/getActiveLineItemsTab",
      getActiveInnerTab: "salesInquiry/getActiveInnerTab",
      getActiveInnerTabSuffix: "salesInquiry/getActiveInnerTabSuffix",
      getAccess: "session/getAccess",
    }),
    active() {
      let index = 0;
      if (this.$route.fullPath.includes("quotes")) {
        index = 1;
      } else if (this.$route.fullPath.includes("orders")) {
        index = 2;
      }
      return index;
    },
    visibleItems(): any {
      return this.items.filter((item) => this.getAccess(item.to));
    },
    isSaveButtonVisible(): boolean {
      const disabledInTabs = ["logs", "change-history", "export"];
      const pathElements = this.$route.fullPath.split("/");
      const lastElement = pathElements[pathElements.length - 1];
      return disabledInTabs.includes(lastElement) === false;
    },
  },

  mounted() {
    // Handle redirect here
    if (this.$route.path === "/sales") {
      if (this.visibleItems.length > 0) {
        this.$router.push(this.visibleItems[0].to);
      } else {
        this.$router.push("/404");
      }
    }
  },
  data: () => ({
    tabToDelete: {} as SalesInquiryMap,
    showConfirmDeleteTab: false,
    activeLineItemTab: 0,
    items: [
      {
        label: "Opportunities",
        to: "/sales/opportunities",
      },
      {
        label: "Quotes",
        to: "/sales/quotes",
      },
      {
        label: "Orders",
        to: "/sales/orders",
      },
    ],
  }),
  methods: {
    ...mapActions({
      changeTitle: "title/changeTitle",
      removeSaleInquiryTab: "salesInquiry/removeOpenedSalesInquiry",
      removeOpenedLineItem: "salesInquiry/removeOpenedLineItem",
      changeActiveTab: "salesInquiry/changeActiveTab",
      changeLineItemTab: "salesInquiry/changeLineItemTab",
      changeActiveInnerTab: "salesInquiry/changeActiveInnerTab",
    }),
    hasChanged(order: any) {
      const oldRecord = JSON.parse(JSON.stringify(order.oldRecord || {}));
      const record = JSON.parse(JSON.stringify(order));
      delete record.oldRecord;

      if (
        (record.id && record.id.includes("New")) ||
        (record.so_id && record.so_id.includes("New"))
      ) {
        return true;
      }

      const ignoreFields = [
        "saleType",
        "id",
        "lineItemsActiveTab",
        "activeInnerTab",
        "lineItemsMap",
        "customer",
      ];
      if (record.notes === undefined) {
        ignoreFields.push("notes");
      }
      if (record.saleType === "orders") {
        ignoreFields.push("rep_items");
      }
      const result = Utils.compareTwoObjects(record, oldRecord, ignoreFields);
      return result;
    },
    handleCloseSaveFieldServiceTab() {
      this.showConfirmDeleteTab = false;
      this.removeSaleInquiryTab({ ...this.tabToDelete });
      this.tabToDelete = {} as SalesInquiryMap;
    },
    handleCancelSaveFieldServiceTab() {
      this.showConfirmDeleteTab = false;
      this.tabToDelete = {} as SalesInquiryMap;
    },
    isReadOnly(tab: any) {
      // Read only should be if the SO has shipped.
      return false;
    },
    formatTabId(tab: any) {
      const saleType = tab.saleType;
      const prop = saleType === "orders" ? "so_id" : "id";
      let id = tab[prop];
      return id.includes("New") ? `#${id.split("-")[1]}` : `#${id}`;
    },
    getTabTitle(tab: any) {
      const saleType = tab.saleType;
      const prop = saleType === "orders" ? "so_id" : "id";
      let title = "";

      if (tab[prop].startsWith("New")) {
        return (title += `New ${
          saleType === "orders"
            ? "Order"
            : saleType === "opportunities"
              ? "Opportunity"
              : "Quote"
        } `);
      }

      title += `${
        saleType === "orders"
          ? "Order"
          : saleType === "opportunities"
            ? "Opportunity"
            : "Quote"
      }`;
      return title;
    },
    handleTabChange(event: any) {
      this.changeActiveTab(event.index !== undefined ? event.index : 0);
    },
    handleLineItemTabChange(event: any, tab: any) {
      this.changeLineItemTab({ orderId: tab.so_id, tabIndex: event.index });
      this.activeLineItemTab = this.getActiveLineItemsTab(tab.so_id);
      if (
        this.activeLineItemTab === 0 &&
        this.getActiveInnerTab(tab.so_id) > 0
      ) {
        const suffix = this.getActiveInnerTabSuffix(tab.so_id);
        this.$router.push(`/sales/orders/${tab.so_id}/${suffix}`);
      }
    },
    handleRemoveOrderTab(tab: any) {
      if (this.hasChanged(tab)) {
        this.tabToDelete = { ...tab };
        this.showConfirmDeleteTab = true;
      } else {
        this.removeSaleInquiryTab({ ...tab });
      }
    },
    handleRemoveLineTab(lis: any, orderId: string) {
      this.removeOpenedLineItem({ lineItem: { ...lis }, orderId: orderId });
      this.$router.push("/sales/orders/" + orderId);
      this.activeLineItemTab = 0;
    },
    handleInnerTabChange(event: any, tab: any) {
      this.changeActiveInnerTab({ orderId: tab.so_id, tabIndex: event.index });
    },
    handleCreatedOrUpdated(hookName?: string) {
      const activeOrderTab = this.getActiveTab;
      const sales = this.getSalesInquiryMap;
      const saleType =
        sales.length > 0 && sales[activeOrderTab - 1]
          ? sales[activeOrderTab - 1].saleType
          : "";
      const prop = saleType === "orders" ? "so_id" : "id";
      const orderId =
        sales.length > 0 && sales[activeOrderTab - 1]
          ? sales[activeOrderTab - 1][prop]
          : "";

      if (saleType === "orders") {
        this.activeLineItemTab = this.getActiveLineItemsTab(orderId) || 0;
        if (
          activeOrderTab > 0 &&
          sales.length > 0 &&
          this.activeLineItemTab > 0
        ) {
          const itemId =
            this.getLineItemsMap(orderId)[this.activeLineItemTab - 1].lis;
          this.$router.push(`/sales/orders/${orderId}/line-item/${itemId}`);
        } else if (
          hookName == "created" &&
          activeOrderTab > 0 &&
          sales.length > 0 &&
          this.activeLineItemTab === 0
        ) {
          this.$router.push(`/sales/orders/${orderId}`);
        }
        if (
          this.activeLineItemTab === 0 &&
          this.getActiveInnerTab(orderId) > 0
        ) {
          const suffix = this.getActiveInnerTabSuffix(orderId);
          this.$router.push(`/sales/orders/${orderId}/${suffix}`);
        }
      } else if (saleType === "opportunities") {
        this.$router.push(`/sales/opportunities/${orderId}`);
      } else if (saleType === "quotes") {
        this.$router.push(`/sales/quotes/${orderId}`);
      }
    },
  },
  created() {
    this.handleCreatedOrUpdated("created");
  },
  updated() {
    this.handleCreatedOrUpdated();
  },
});
